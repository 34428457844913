<template>
  <div v-if="Intro" class="home-page">
    <VideoPlayer
      :showPlayer="true"
      :autoplay="false"
      :cover="true"
      :skippable="skippable"
      :showControl="false"
      :content="{ videoFile: `/media/${$vuetify.breakpoint.smAndUp ? Intro.video.id : Intro.videoMobile.id}` }"
      ref="player"
      @skip="onIntroSkip"
      @zoom.once="onZoom"
      @videoFinished="onVideoEnd"
    />
    <div class="home-content bg-blur">
      <div class="intro-image-sequence">
        <ImageSequence
          :content="Intro.imageSequence"
          :follow-mouse="!($root.platform.mobile || $root.platform.phone || $root.platform.tablet || $root.platform.ipad)"
        />
      </div>
      <div class="intro-content-text">
        <MarkdownBlock
          v-if="$root.translations[$language.current].introTitle"
          :text="$root.translations[$language.current].introTitle"
          tag="h1"
          class="animhome mb-2"
          inline
        />
        <MarkdownBlock
          v-if="$root.translations[$language.current].introText"
          :text="$root.translations[$language.current].introText"
          tag="p"
          class="animhome gold mb-8"
          inline
        />
        <button-text @click="onEnter()" class="animhome" underline>{{$root.translations[$language.current].enter}}</button-text>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonText from '../atoms/ButtonText.vue'
import VideoPlayer from '../atoms/VideoPlayer.vue'
import { gsap } from 'gsap'
import MarkdownBlock from '@/atoms/MarkdownBlock.vue'
import IntroQuery from '@/graphql/GetIntro.gql'
import ImageSequence from '@/molecules/ImageSequence.vue'

export default {
  name: 'Home',
  components: {
    VideoPlayer,
    ButtonText,
    MarkdownBlock,
    ImageSequence
  },
  apollo: {
    Intro: {
      query: IntroQuery,
      variables () {
        return {
          id: '3889cffd-e824-481e-b032-ad258cee7762'
        }
      },
      async result () {
        this.$events.$emit('page-has-audio', this.Intro.audio.id)
        this.show()
      }
    }
  },
  data () {
    return {
      videoIntroEnded: false,
      skippable: true
    }
  },
  beforeDestroy () {
    this.enterTl && this.enterTl.kill()
    this.leaveTl && this.leaveTl.kill()
  },
  beforeRouteLeave (to, from, next) {
    if (to.name !== from.name) {
      gsap.fromTo(this.$el, { autoAlpha: 1 }, { autoAlpha: 0, duration: 1, onComplete: next })
    }
  },
  async mounted () {
    await this.$nextTick()
    this.trackPageView()
    this.$events.$emit('stop-audio')
    this.$root.menuOpen = false
    setTimeout(() => {
      this.$events.$emit('page-has-audio', this.Intro.audio.id)
    }, 2100)
  },
  methods: {
    async show () {
      await this.$nextTick()
      this.enterTl = gsap.timeline()
      this.enterTl.fromTo(this.$el, { autoAlpha: 0 }, { autoAlpha: 1, duration: 1 })
      this.enterTl.fromTo('.animhome', { autoAlpha: 0, y: 30 }, { autoAlpha: 1, y: 0, duration: 1, stagger: 0.35 })
    },
    onEnter () {
      this.$root.pushAnalyticsEvent('click_on_enter', 'Home page', 'click', 'Cta enter', '')
      this.$events.$emit('play-audio')
      this.$refs.player.playVideo(true)
      this.leaveTl = gsap.timeline()
      this.leaveTl.fromTo(this.$el.querySelector('.home-content'), { autoAlpha: 1 }, { autoAlpha: 0, duration: 1 })
    },
    onZoom () {
      this.skippable = false
      gsap.fromTo(this.$refs.player.$el, { scaleX: 1, scaleY: 1 }, { scaleX: 1.4, scaleY: 1.4, duration: 2, onComplete: this.onVideoEnd })
    },
    onVideoEnd () {
      if (!this.videoIntroEnded) {
        this.skippable = false
        this.videoIntroEnded = true
        this.$root.menuOpen = true
        this.$events.$emit('stop-audio')
        setTimeout(() => {
          this.$events.$emit('menu-360-rotation')
        }, 1250)
      }
    },
    onIntroSkip () {
      this.$root.pushAnalyticsEvent('click_on_skip_intro', 'Home page', 'click', 'Button skip intro', '')
    },
    trackPageView () {
      if (this.$root.useAnalytics && this.$gtag) {
        this.$gtag.pageview({
          page_title: 'Home Page | Royal Salute',
          page_path: window.location.pathname,
          page_location: window.location.href,
          send_page_view: false
        })
      }
    }
  },
  watch: {
    $route: async function (to, from) {
      if (to.params.language !== from.params.language) {
        await this.$nextTick()
        this.enterTl && this.enterTl.kill()
        this.leaveTl && this.leaveTl.kill()
        this.show()
      }
    },
    '$root.useAnalytics' (newValue, oldValue) {
      if (!oldValue && newValue) {
        this.trackPageView()
      }
    }
  },
  metaInfo () {
    return {
      title: 'Home Page'
    }
  }
}
</script>

<style scoped lang="scss">
.home-page {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;

  p.animhome {
    text-align: center;
    font: normal normal normal 14px/18px futura-pt;
    letter-spacing: 2.8px;
    color: $c-gold;
  }
}

.home-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.intro-image-sequence {
  position: absolute;
  z-index: 0;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.intro-content-text {
  text-align: center;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 100%;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 50%;
    height: 180px;
    background-color: $c-blue-75;
    filter: blur(20px);
  }
}
</style>
